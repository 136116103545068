
import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import vClickOutside from 'v-click-outside';

@Component
export default class BaseInput extends Vue {
  @Prop({ default: '' }) value!: string;
  @Prop({ default: '' }) label!: string;
  @Prop({ default: '' }) placeholder!: string;
  @Prop({ default: '' }) errorMessage!: string;
  @Prop({ default: '' }) description!: string;
  @Prop({ default: '' }) error!: string;
  @Prop({ default: '' }) icon!: string;
  @Prop({ default: '' }) type!: string;
  @Prop({ default: null }) maxLength!: number;
  @Prop({ default: false }) onlyLetters!: boolean;
  @Prop({ default: false }) onlyNumbers!: boolean;
  @Prop({ default: false }) XXSfilter!: boolean;
  @Prop({ default: false }) nameCompanyFilter!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) userNameFilter!: boolean;
  @Prop({ default: false }) isWarningEnabled!: boolean;

  isShowWarning = false;
  isInputClicked = false;

  onInput(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (this.onlyLetters) {
      target.value = target.value.replace(/[^a-z A-Z]/g, '');
    }
    if (this.onlyNumbers) {
      target.value = target.value.replace(/[^0-9]/g, '');
    }
    if (this.XXSfilter) {
      target.value = target.value.replace(/[^a-z A-Z 0-9]/g, '');
    }
    if (this.userNameFilter) {
      target.value = target.value.replace(/[^a-z A-Z-]/g, '');
    }
    if (this.nameCompanyFilter) {
      target.value = target.value.replace(/[^a-z A-Z.0-9-!&]/g, '');
    }
    if (this.maxLength && target.value.length > this.maxLength) {
      this.isShowWarning = true;
      target.value = target.value.slice(0, this.maxLength);
    }

    this.$emit('input', target.value.trim());
  }
  checkField(): void {
    if (this.isInputClicked) {
      this.$emit('checkField');
      this.isInputClicked = false;
    }
  }

  created(): void {
    Vue.use(vClickOutside);
  }

  @Watch('value')
  inputBackToPermittedLength(): void {
    this.isShowWarning = !(this.value.length < this.maxLength);
  }
}
